<template>
  <div>
    <a-textarea
      placeholder="textarea with clear icon"
      allow-clear
      @change="onChange"
      v-model="value"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ""
    };
  },
  methods: {
    onChange(val) {
      let str = this.value.replace(/\n/g, "<br/>");
    }
  }
};
</script>

<style scoped></style>
